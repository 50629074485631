import { Playground, Props } from 'docz';
import { Reply, Avatar, Icon, Colors, TextButton, ButtonSize, Body2, Badge, ReplySize } from '@class101/ui';
import { IMAGE_URL } from "../../../../../docs/components/Avatar";
import { LARGE_CONTENT } from "../../../../../docs/components/Comment";
import * as React from 'react';
export default {
  Playground,
  Props,
  Reply,
  Avatar,
  Icon,
  Colors,
  TextButton,
  ButtonSize,
  Body2,
  Badge,
  ReplySize,
  IMAGE_URL,
  LARGE_CONTENT,
  React
};