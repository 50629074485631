import { Playground, Props } from 'docz';
import { GridList, Body1, Caption1 } from '@class101/ui';
import { imageSrc } from "../../../../../docs/components/interface";
import * as React from 'react';
export default {
  Playground,
  Props,
  GridList,
  Body1,
  Caption1,
  imageSrc,
  React
};