import { Playground, Props } from 'docz';
import { Callout, CalloutStatus, Icon, Colors } from '@class101/ui';
import { imageSrc } from "../../../../../docs/components/interface";
import * as React from 'react';
export default {
  Playground,
  Props,
  Callout,
  CalloutStatus,
  Icon,
  Colors,
  imageSrc,
  React
};