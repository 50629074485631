import { Section, GridList, Body1 } from '@class101/ui';
import { Playground, Props } from 'docz';
import { imageSrc } from "../../../../../docs/components/interface";
import { PlayGroundBanner } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Section,
  GridList,
  Body1,
  Playground,
  Props,
  imageSrc,
  PlayGroundBanner,
  React
};