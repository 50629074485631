import { Playground, Props } from 'docz';
import { Avatar, AvatarSize, Icon, Colors, Badge } from '@class101/ui';
import { IMAGE_URL } from "../../../../../docs/components/Avatar";
import * as React from 'react';
export default {
  Playground,
  Props,
  Avatar,
  AvatarSize,
  Icon,
  Colors,
  Badge,
  IMAGE_URL,
  React
};