import { Playground, Props } from 'docz';
import { Colors, Button, Grid, Row, Caption1, Card, CoverRatio, Badge, Icon, Divider } from '@class101/ui';
import { NoHiddenStyleCol } from "../../../../../docs/components/Col";
import { imageSrc } from "../../../../../docs/components/interface";
import * as React from 'react';
export default {
  Playground,
  Props,
  Colors,
  Button,
  Grid,
  Row,
  Caption1,
  Card,
  CoverRatio,
  Badge,
  Icon,
  Divider,
  NoHiddenStyleCol,
  imageSrc,
  React
};