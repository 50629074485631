import { Playground, Props } from 'docz';
import { Navigation, NavigationSection, NavigationDivider, Icon } from '@class101/ui';
import { PlayGroundContainer, ThemeProvider } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Navigation,
  NavigationSection,
  NavigationDivider,
  Icon,
  PlayGroundContainer,
  ThemeProvider,
  React
};