import { Playground, Props } from 'docz';
import { Comment, Avatar, Icon, Colors, TextButton, ButtonSize, Body2, Badge, CommentSize } from '@class101/ui';
import { IMAGE_URL } from "../../../../../docs/components/Avatar";
import { LARGE_CONTENT } from "../../../../../docs/components/Comment";
import * as React from 'react';
export default {
  Playground,
  Props,
  Comment,
  Avatar,
  Icon,
  Colors,
  TextButton,
  ButtonSize,
  Body2,
  Badge,
  CommentSize,
  IMAGE_URL,
  LARGE_CONTENT,
  React
};