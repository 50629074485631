import { Playground, Props } from 'docz';
import { Button, ControlGroup, Input, ButtonSize, ButtonColor } from '@class101/ui';
import * as React from 'react';
export default {
  Playground,
  Props,
  Button,
  ControlGroup,
  Input,
  ButtonSize,
  ButtonColor,
  React
};