import { Playground, Props } from 'docz';
import { ButtonGroup, Button, ButtonColor, Theme, Icon } from '@class101/ui';
import { PlayGroundContainer } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  ButtonGroup,
  Button,
  ButtonColor,
  Theme,
  Icon,
  PlayGroundContainer,
  React
};