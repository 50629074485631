import { Tooltip, Position, Button, TooltipColor } from '@class101/ui';
import { Playground, Props } from 'docz';
import { Component } from "../../../../../docs/overlays/Tooltip";
import * as React from 'react';
export default {
  Tooltip,
  Position,
  Button,
  TooltipColor,
  Playground,
  Props,
  Component,
  React
};