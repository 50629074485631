import { Playground, Props } from 'docz';
import { Icon, Colors, AnchorButton, Button, ButtonColor, ButtonSize, Theme } from '@class101/ui';
import { PlayGroundButtonContainer } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Icon,
  Colors,
  AnchorButton,
  Button,
  ButtonColor,
  ButtonSize,
  Theme,
  PlayGroundButtonContainer,
  React
};