import { Playground, Props } from 'docz';
import { Headline1, Headline2, Headline3, Subtitle1, Subtitle2, Body1, Body2, Caption1, Caption2 } from '@class101/ui';
import * as React from 'react';
export default {
  Playground,
  Props,
  Headline1,
  Headline2,
  Headline3,
  Subtitle1,
  Subtitle2,
  Body1,
  Body2,
  Caption1,
  Caption2,
  React
};