import { Playground, Props } from 'docz';
import { Swiper, Slide, Colors, ArrowNavigation, DefaultNavigation, Section } from '@class101/ui';
import { PlayGroundBanner } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Swiper,
  Slide,
  Colors,
  ArrowNavigation,
  DefaultNavigation,
  Section,
  PlayGroundBanner,
  React
};