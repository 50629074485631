import { Playground, Props } from 'docz';
import { Skeleton, Colors, Headline2, Body2, Body1 } from '@class101/ui';
import * as React from 'react';
export default {
  Playground,
  Props,
  Skeleton,
  Colors,
  Headline2,
  Body2,
  Body1,
  React
};