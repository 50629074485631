import { Playground, Props } from 'docz';
import { Icon, Colors } from '@class101/ui';
import { IconItemContainer } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Icon,
  Colors,
  IconItemContainer,
  React
};