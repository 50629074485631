import { Playground, Props } from 'docz';
import { Icon, Colors, TextButton, ButtonColor, ButtonSize, Theme } from '@class101/ui';
import { PlayGroundContainer } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Icon,
  Colors,
  TextButton,
  ButtonColor,
  ButtonSize,
  Theme,
  PlayGroundContainer,
  React
};