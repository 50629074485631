import { Playground, Props } from 'docz';
import { Tabs, Badge, Icon, Colors, Theme } from '@class101/ui';
import { PlayGroundContainer } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Tabs,
  Badge,
  Icon,
  Colors,
  Theme,
  PlayGroundContainer,
  React
};