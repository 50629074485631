import { Playground, Props } from 'docz';
import { Switch, Colors, Icon } from '@class101/ui';
import { PlayGroundButtonContainer } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Switch,
  Colors,
  Icon,
  PlayGroundButtonContainer,
  React
};