import { Playground, Props } from 'docz';
import { Badge, Colors, Icon } from '@class101/ui';
import * as React from 'react';
export default {
  Playground,
  Props,
  Badge,
  Colors,
  Icon,
  React
};