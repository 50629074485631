import { Section, Divider } from '@class101/ui';
import { Playground, Props } from 'docz';
import { StyledDivider } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Section,
  Divider,
  Playground,
  Props,
  StyledDivider,
  React
};