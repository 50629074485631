import { Playground, Props } from 'docz';
import { Carousel, Slide, CarouselNavigationPosition, CarouselPaginationTheme, Section, Colors, ViewAllButton } from '@class101/ui';
import { imageSrc } from "../../../../../docs/components/interface";
import { PlayGroundBanner, PlayGroundSectionCarouselContent, SwiperCard, PreloadImage } from "../../../../../docs/docsComponents";
import * as React from 'react';
export default {
  Playground,
  Props,
  Carousel,
  Slide,
  CarouselNavigationPosition,
  CarouselPaginationTheme,
  Section,
  Colors,
  ViewAllButton,
  imageSrc,
  PlayGroundBanner,
  PlayGroundSectionCarouselContent,
  SwiperCard,
  PreloadImage,
  React
};